import React from 'react';
import '../styles/styles.scss';
import SEO from '../components/seo';
import SmartbakApp from '../images/download_smartbak_app.webp';
import DownloadAppButtons from '../components/app-buttons/AppButtons';
import { Link } from 'gatsby';
import QRButton from '../components/app-buttons/QR-code';

const DownloadPage = () => {
  return (
    <>
      <SEO
        title="download de Smartbak app"
        description="download de Smartbak app en huur jouw aanhangwagen per uur. Beschikbaar in de Apple en Play store."
      />
      <div className="download-page-wrapper">
        <Link to="/" className="download-page-home-button">
          X
        </Link>
        <div className="download-page-section" style={{ paddingTop: 100 }}>
          <div>
            <img
              style={{ maxWidth: '100%', maxHeight: '100%' }}
              src={SmartbakApp}
              alt="download de Smartbak app"
            ></img>
          </div>
          <div>
            <h1>Download de Smartbak app</h1>
            <div className="hide-desktop">
              <DownloadAppButtons />
            </div>
            <div className="hide-tablet-and-mobile">
              <QRButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadPage;
