import { useStaticQuery, graphql } from 'gatsby';

export const DownloadApp = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiHomepage(locale: { eq: "nl-NL" }) {
        appStore {
          image {
            alternativeText
            caption
            url
          }
          link
        }
        downloadImage {
          alternativeText
          caption
          url
        }
        downloadTitle
      }
    }
  `);

  const { downloadImage, downloadTitle, appStore } = data.strapiHomepage;
    console.log('DOWNLOADIMAGE', downloadImage)
  return (
    <section id="meld-je-aan">
      <div className="banner-wrapper">
        <div className="banner-row">
          <div
            className="banner-cta__image"
            style={{
                backgroundImage:`url(${downloadImage.url})`
            }}
            // 
          ></div>
          <div>
            <div className="banner-headline">
              <h2 style={{ margin: 0 }}>{downloadTitle}</h2>
              <p>Download de app om te huren.</p>
              <div className="app-logos-banner" style={{ marginTop: '1rem' }}>
                {appStore.map((store) => (
                  <div>
                    <a href={store.link}>
                      <img
                        src={`${store.image.url}`}
                        className="appstorelogo"
                        alt={store.image.alternativeText}
                      ></img>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
