import React from 'react';

const locations = [
  'Amsterdam',
  'Amsterdam-Noord',
  'Amstelveen',
  'Apeldoorn',
  'Sassenheim',
  'Heemstede',
  'Breda',
  'Den Bosch',
  'Den Haag',
  'Enschede',
  'Arnhem',
  'Tilburg',
  'Utrecht/Nieuwegein',
  'Moordrecht',
  'Veldhoven',
];
const SlidingText = () => {
  return (
    <div className="marquee">
      <p className="marquee-content">
        {locations.sort().map((location) => <span style={{marginRight: 24}} key={location}>{location}</span>)}
      </p>
    </div>
  );
};

export default SlidingText;
