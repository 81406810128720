import { Container } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const Testimonials = ({ testimonials, testimonialsTitle }) => {
  const starRating = (rating) => {
    const stars = Array.from({ length: Math.round(rating) }, (_, index) => (
      <StarIcon key={index} />
    ));

    return stars;
  };

  return (
    <section>
      <div className="testimonials-background">
        <Container>
          <h2>{testimonialsTitle}</h2>
          <div className="testimonials-wrapper">
            {testimonials.map((testimonial) => (
              <div className="testimonial" key={testimonial.strapi_id}>
                <div className="testimonial__stars">
                  {starRating(testimonial.stars / 2)}
                </div>
                <div className="testimonial__body">
                  {testimonial.description}
                </div>
                <div className="testimonial__author">
                  {testimonial.name} uit {testimonial.place}
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Testimonials;
