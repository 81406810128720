import React from 'react';
import Layout from '../components/layout';
import { AboutHome } from '../components/about/about-home';
import { HighlightedProducts } from '../components/products/highlighted';
import { DownloadApp } from '../components/banners/download';
import { FaqsHome } from '../components/faqs/faqs-home';
import { useStaticQuery, graphql } from 'gatsby';
import SlidingText from './slider';
import SEO from '../components/seo';
import HeroHome from '../components/hero/hero-home';
import { USPs } from '../components/instructions/usps';
import { HowItWorksVideos } from '../components/instructions/videos';
import { HeroVideo } from '../components/video/heroVideo';
import Testimonials from '../components/testimonials/testimonials';

export function Index() {
  const data = useStaticQuery(graphql`
    query {
      strapiHomepage(locale: { eq: "nl-NL" }) {
        seo {
          metaDescription
          metaImage {
            alternativeText
            caption
            url
          }
          metaTitle
        }
      }
    }
  `);

  const { seo } = data.strapiHomepage;

  return (
    <Layout>
      <SEO
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage?.url}
      />
      <HeroHome />
      <SlidingText />
      <USPs />
      <HighlightedProducts />
      <DownloadApp />
      <AboutHome />
      <HeroVideo />
      <Testimonials />
      <HowItWorksVideos />
      <FaqsHome />
    </Layout>
  );
}

export default Index;
