import * as React from "react"
import PropTypes from "prop-types"
import "../styles/styles.scss"
import Navbar from "./navigation/Navbar";
import Footer from "./navigation/Footer";
import 'normalize.css'

const Layout = ({ children }) => {
  const [scrolled, setScrolled] = React.useState(false)
  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <Navbar scrolled={scrolled} />
      <main>
        {children}
      </main>
      <Footer />
    </React.Fragment>
  )
}

export default Layout;
