import { Container } from '@mui/material';
import footerlogosmartbak from '../../images/svg/footerlogosmartbak.png';
import appstorelogo from '../../images/svg/app-store.png';
import googleplaylogo from '../../images/svg/play-store.png';
import emailicon from '../../images/svg/emailicon.png';
import phoneicon from '../../images/svg/phoneicon.png';
import { Link, useStaticQuery, graphql } from 'gatsby';

const FooterNav = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiFooter(locale: { eq: "nl-NL" }) {
        contact {
          image {
            alternativeText
            caption
            url
          }
          text
          link
          strapi_id
        }
        appStore {
          image {
            alternativeText
            caption
            url
          }
          link
          strapi_id
        }
        appStoreTitle
        contactTitle
        locale
        smartbak {
          text
          link
          strapi_id
        }
        smartbakTitle
        smartbakImage {
          alternativeText
          caption
          url
        }
      }
    }
  `);

  const {
    appStore,
    appStoreTitle,
    contact,
    contactTitle,
    locale,
    smartbak,
    smartbakImage,
    smartbakTitle,
  } = data.strapiFooter;

  return (
    <footer>
      <div className="footer-blackbg">
        <Container>
          <div className="row-33">
            <div className="footer-left">
              <img
                src={`${smartbakImage.url}`}
                alt={smartbakImage.alternativeText}
                width={100}
              ></img>
              <ul>
                {smartbak.map((link) => (
                  <li key={link.strapi_id}>
                    <Link to={`/${link.link}`}>{link.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className="footer-logos">
                <p className="footer-logos__headline "> {appStoreTitle} </p>
                {appStore.map((store) => (
                  <div key={store.strapi_id}>
                    <a href={store.link}>
                      <img
                        src={`${store.image.url}`}
                        className="appstorelogo"
                        alt={store.image.alternativeText}
                      ></img>
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="footer-contact">
                <p className="footer-logos__headline"> {contactTitle} </p>
                {contact.map((cont) => (
                  <div key={cont.strapi_id}>
                    <img
                      src={`${cont.image.url}`}
                      alt={cont.image.alternativeText}
                      style={{ width: 24 }}
                    ></img>{' '}
                    <a href={cont.link}>{cont.text}</a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default FooterNav;
