export const AboutCity = ({ aboutTitle, aboutDescription, aboutImage }) => {
  return (
    <>
      <div id="over-smartbak" style={{ marginBottom: '100px' }}></div>
      <section>
        <div
          style={{
            maxWidth: 920,
            padding: '0 12px',
            margin: '0 auto',
          }}
        >
          <div className="row-50">
            <div className="about-image">
              <img
                src={`${aboutImage.url}`}
                alt={aboutImage.alternativeText}
              ></img>
            </div>
            <div className="about-text">
              <h2>{aboutTitle}</h2>
              <p>{aboutDescription?.data?.aboutDescription}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
