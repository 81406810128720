import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import DownloadAppButtons from '../app-buttons/AppButtons';
import { useStaticQuery, graphql } from 'gatsby';
import QRButton from '../app-buttons/QR-code';

const HeroHome = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiHomepage(locale: { eq: "nl-NL" }) {
        appStore {
          image {
            alternativeText
            caption
            url
          }
          link
        }
        background {
          alternativeText
          caption
          url
        }
        description {
          data {
            description
          }
        }
        locale
        title
      }
    }
  `);

  const { title, description, background } = data.strapiHomepage;

  return (
    <div
      className="hero-alt-bg"
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${background.url})`,
      }}
    >
      <div className="hero-alt-content">
        <div>
          <h1>{title}</h1>
          <ReactMarkdown>{description?.data?.description}</ReactMarkdown>
          <div className="hide-desktop">
            <DownloadAppButtons />
          </div>
        </div>
        <div className="hide-tablet-and-mobile">
          <QRButton />
        </div>
      </div>
    </div>
  );
};

export default HeroHome;
