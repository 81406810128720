import { Container } from '@mui/system';
import { useStaticQuery, graphql } from 'gatsby';

export const USPs = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiHomepage(locale: { eq: "nl-NL" }) {
        processStep {
          instruction
          image {
            alternativeText
            url
            caption
          }
          usage
          strapi_id
        }
      }
    }
  `);

  const { processStep } = data.strapiHomepage;

  return (
    <section id="ups" style={{ marginBottom: 0 }}>
      <Container>
        <div className="row-33 spacing-wrapper">
          {processStep.map((step) => (
            <div className="row-33box" key={step.strapi_id}>
              <img
                src={`${step.image.url}`}
                alt={step.image.alternativeText}
                heigth={48}
              ></img>
              <p className="subicon1">{step.instruction}</p>
              <p className="subicon2">{step.usage}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};
