import { Container } from '@mui/material';
import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = () => <Layout>
    <div className='hero-background'>
        <Container style={{ height: '100%' }}>
            <div className='row-50 hero-wrapper'>
                <div className='hero-left'>
                    <h1 className='firstpage-background-h1'>
                        Pagina niet gevonden
                    </h1>
                   
                </div>
            </div>
        </Container>
    </div>
</Layout>

export default NotFoundPage;
