import React from 'react';
import Layout from '../components/layout';
import { Container } from '@mui/system';
import SEO from '../components/seo';
import { CookieDeclaration } from '../components/cookies/CookieDeclaration';

const Location = () => {
  const locations = [
    'Amsterdam',
    'Amsterdam-Noord',
    'Amstelveen',
    'Apeldoorn',
    'Sassenheim',
    'Heemstede',
    'Breda',
    'Den Bosch',
    'Den Haag',
    'Enschede',
    'Arnhem',
    'Tilburg',
    'Utrecht/Nieuwegein',
    'Moordrecht',
    'Veldhoven',
  ];

  return (
    <Layout>
      <SEO
        title="download de Smartbak app"
        description="download de Smartbak app en huur jouw aanhangwagen per uur. Beschikbaar in de Apple en Play store."
      />
      <div className="bg-content">
        <div>
          <h1 className="">Onze Locaties</h1>
        </div>
      </div>
      <section>
        <Container style={{ minHeight: 600 }}>
          <h2>Nederland</h2>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
              gridGap: '1rem',
              marginTop: '1rem',
            }}
          >
            {locations.map((location, index) => (
              <div key={index} style={{ fontSize: 26 }}>
                {location}
              </div>
            ))}
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export default Location;
