import Layout from '../components/layout';
//import SEO from "../components/seo";
//import CtaForm from '../components/cta-form/form';
import { useState } from 'react';
import { Container } from '@mui/system';
import { useStaticQuery, StaticQuery, graphql } from 'gatsby';
import FaqsAccordion from '../components/faqs/faqs-accordion';
import SEO from '../components/seo';

const Faq = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiCategory {
        nodes {
          category
          faqs {
            faq {
              question
              answer {
                data {
                  answer
                }
              }
              strapi_id
            }
          }
          locale
          strapi_id
        }
      }
      strapiFaqPage(locale: { eq: "nl-NL" }) {
        description {
          data {
            description
          }
        }
        faqExpand {
          alternativeText
          caption
          url
        }
        locale
        slug
        strapi_id
        title
        seo {
          metaDescription
          metaImage {
            alternativeText
            caption
            url
          }
          metaTitle
        }
      }
    }
  `);

  const { nodes } = data.allStrapiCategory;

  const { description, title, faqExpand, seo } = data.strapiFaqPage;

  const [clickedFaq, setClickedFaq] = useState(1);
  const [activeCat, setActiveCat] = useState(0);
  const [prevActiveCat, setPrevActiveCat] = useState(-1);

  const toggleOpen = (nextCat) => {
    if (nextCat !== activeCat) {
      setPrevActiveCat(activeCat);
      setActiveCat(nextCat);
    }
  };

  return (
    <Layout>
      <SEO
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage?.url}
      />
      <div className="bg-content">
        <div>
          <h1 className="">{title}</h1>
          <p className="firstpage-h1">{description?.data?.description}</p>
        </div>
      </div>
      <Container>
        <div className="faq__background">
          <section id="faqs">
            <Container>
              <div className="faq-cat-wrapper">
                <div className="faq-cat-header">
                  {nodes.map(
                    (node, i) =>
                      node.locale === 'nl-NL' && (
                        <button
                          onClick={() => {
                            toggleOpen(i);
                          }}
                          className={`primary-button ${
                            activeCat === i && 'active'
                          }`}
                        >
                          {node.category}
                        </button>
                      )
                  )}
                </div>
                <div className="">
                  {activeCat >= 0 &&
                    activeCat !== prevActiveCat &&
                    nodes.map((node, i) => (
                      <div>
                        {i === activeCat &&
                          node.faqs.map((f) => (
                            <FaqsAccordion
                              faq={f}
                              clickedFaq={clickedFaq}
                              setClickedFaq={setClickedFaq}
                              faqExpand={faqExpand}
                            />
                          ))}
                      </div>
                    ))}
                </div>
              </div>
            </Container>
          </section>
        </div>
      </Container>
    </Layout>
  );
};

export default Faq;
