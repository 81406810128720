import React from 'react'
import Layout from '../components/layout'
import { Container } from '@mui/system';
import { CookieDeclaration } from '../components/cookies/CookieDeclaration';

const CookiesPage = () => {
  return (
    <Layout>
        <section>
            <Container style={{minHeight: 600}}>
                <h1>Cookies</h1>
                <CookieDeclaration />
            </Container>
            
        </section>
    </Layout>
  )
}

export default CookiesPage