import React from 'react';
import Layout from '../components/layout';
import { AboutCity } from '../components/about/about-city';
import { HighlightedProducts } from '../components/products/cityhighlighted';
import { CityDownloadApp } from '../components/banners/citydownload';
import { FaqsHome } from '../components/faqs/faqs-city';
import SEO from '../components/seo';
import HeroCity from '../components/hero/hero-city';
import { USPs } from '../components/instructions/cityusps';
import { HowItWorksVideos } from '../components/instructions/cityvideos';
import { HeroVideo } from '../components/video/heroVideoCity';
import Testimonials from '../components/testimonials/citytestimonials';

export default function CityPage({ pageContext }: any) {
  const {
    seo,
    aboutDescription,
    aboutImage,
    aboutTitle,
    appStore,
    downloadImage,
    downloadTitle,
    faqButtonText,
    faqDescription,
    faqExpand,
    faqTitle,
    faqs,
    faqSlug,
    background,
    description,
    title,
    processStep,
    howItWorksTitle,
    howItWorksVideosD,
    productPrice,
    productPricesTitle,
    testimonials,
    testimonialsTitle,
    video,
  } = pageContext;

  return (
    <Layout>
      <SEO
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage?.url}
      />
      <HeroCity
        title={title}
        description={description}
        background={background}
      />

      <USPs processStep={processStep} />
      <HighlightedProducts
        productPrice={productPrice}
        productPricesTitle={productPricesTitle}
      />
      <CityDownloadApp
        downloadImage={downloadImage}
        downloadTitle={downloadTitle}
        appStore={appStore}
      />
      <AboutCity
        aboutTitle={aboutTitle}
        aboutDescription={aboutDescription}
        aboutImage={aboutImage}
      />
      <HeroVideo video={video} />
      <Testimonials
        testimonials={testimonials}
        testimonialsTitle={testimonialsTitle}
      />
      <HowItWorksVideos
        howItWorksTitle={howItWorksTitle}
        howItWorksVideosD={howItWorksVideosD}
      />
      <FaqsHome
        faqButtonText={faqButtonText}
        faqDescription={faqDescription}
        faqExpand={faqExpand}
        faqTitle={faqTitle}
        faqs={faqs}
        faqSlug={faqSlug}
      />
    </Layout>
  );
}
