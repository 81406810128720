import { Container } from '@mui/system';
import { useStaticQuery, graphql } from 'gatsby';

export const HowItWorksVideos = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiHomepage(locale: { eq: "nl-NL" }) {
        howItWorksTitle
        howItWorksVideos {
          strapi_id
          video {
            alternativeText
            url
            name
          }
          placeholderImage {
            url
            alternativeText
            name
          }
        }
      }
    }
  `);

  const { howItWorksTitle, howItWorksVideos } = data.strapiHomepage;
  return (
    <>
      <div id="hoe-werkt-het"></div>
      <section>
        <Container>
          <h2 style={{textAlign: 'center'}}>{howItWorksTitle}</h2>
          <div className='video-grid-50'>
            {howItWorksVideos.map((item) => (
              <div key={item.strapi_id}>
                <video
                  src={item.video.url}
                  type="video/mp4"
                  controls
                  poster={item.placeholderImage.url}
                  style={{ maxWidth: '100%', borderRadius: '6px' }}
                >Your browser does not support the video tag.</video>
              </div>
            ))}
          </div>
        </Container>
      </section>
    </>
  );
};
