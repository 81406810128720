import React from 'react';
import Layout from '../components/layout';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Container } from '@mui/material';
import SEO from '../components/seo';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const SimplePageTemplate = ({ pageContext }: any) => {
  const { body, image, seo, contactbox, title, description } = pageContext;
  return (
    <Layout>
      <SEO
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage?.url}
      />
      <div className="bg-content">
        <div>
          <h1 className="">{title}</h1>
          <p className="firstpage-h1">{description}</p>
        </div>
      </div>
      <section>
        <Container style={{ padding: '48px 0' }}>
          <div className='simplepage-grid'>
            <div>
            {image && (
              <img
                src={image.url}
                alt={image.alternativeText}
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: 300,
                  borderRadius: 12,
                }}
              />
            )}
            <div style={{ padding: 12 }}>
              <ReactMarkdown>{body?.data?.body}</ReactMarkdown>
            </div>
            </div>
            <div>
            {contactbox && (
              <div className='contactbox'>
                <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                  <div>
                    <img
                      src={contactbox.image?.url}
                      alt={contactbox.image?.alternativeText}
                      style={{
                        width: 96,
                        height: 96,
                        borderRadius: '100%',
                        background: 'white',
                        objectFit: 'contain',
                      }}
                    ></img>
                  </div>
                  <div>
                    <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                      {contactbox.name}
                    </h3>
                  </div>
                </div>
                <p>{contactbox.description}</p>
                <p>
                  <a href={`tel:${contactbox.phone}`} style={{display: 'flex', alignItems: 'center', gap: 6 }}><PhoneIcon /> {contactbox.phone}</a>
                </p>
                <p>
                  <a href={`mailto:${contactbox.email}`} style={{display: 'flex', alignItems: 'center', gap: 6 }}><EmailIcon /> {contactbox.email}</a>
                </p>
              </div>
            )}
            </div>
          </div>
        </Container>
      </section>
      <section></section>
    </Layout>
  );
};

export default SimplePageTemplate;
