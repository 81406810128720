import { Container } from '@mui/system';
import { useState } from 'react';
import FaqsAccordion from './faqs-accordion';
import { Link } from 'gatsby';

export const FaqsHome = (props) => {
  const { faqButtonText, faqDescription, faqExpand, faqTitle, faqs, faqSlug } =
    props;
  const [clickedFaq, setClickedFaq] = useState(1);

  return (
    <>
      <div id="faqs" style={{ marginBottom: '100px' }}></div>
      <div className="faq__background">
        <section>
          <Container>
            <div className="faq-text">
              <h2 className="">{faqTitle}</h2>
              <p className="firstpage-h1">
                {faqDescription?.data?.faqDescription}
              </p>
            </div>
            <div className="faq-wrapper">
              {faqs.map((f) => (
                <FaqsAccordion
                  faq={f}
                  clickedFaq={clickedFaq}
                  setClickedFaq={setClickedFaq}
                  faqExpand={faqExpand}
                  key={f.strapi_id}
                />
              ))}
              <div style={{ textAlign: 'center' }}>
                <Link to={`${faqSlug}`} key={faqExpand.strapi_id}>
                  <button className={'button primary-button-outline'}>
                    {faqButtonText}
                  </button>
                </Link>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};
