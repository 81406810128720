import { Container } from '@mui/material';
import { useStaticQuery, graphql } from 'gatsby';

export const HeroVideo = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiHomepage(locale: { eq: "nl-NL" }) {
        video {
          video {
            url
          }
          placeholderImage {
            url
          }
          videoTitle
        }
      }
    }
  `);

  const { video } = data.strapiHomepage;

  return (
    <section>
      <Container>
        {video.videoTitle && 
          <h2 style={{textAlign: 'center'}}>{video.videoTitle}</h2>
        }
        <video
          src={video.video.url}
          type="video/mp4"
          controls
          poster={video.placeholderImage.url}
          style={{ maxWidth: '100%', borderRadius: '6px' }}
        >
          Your browser does not support the video tag.
        </video>
      </Container>
    </section>
  );
};
