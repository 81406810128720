export const pushDataLayerFaq = (faq, clickedFaq) => {
    if (clickedFaq === faq.strapi_id) { return; }

    const dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event': 'click_faq',
        'id': faq.strapi_id,
        'question': `${faq.question}`,
        'answer': `${faq.answer?.data?.answer}`
    });
}