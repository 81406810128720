import React from "react";

const isBrowser = () => typeof window !== "undefined";

const COOKIEBOT_DECLARATION_WRAPPER_ID = "cookiebot-declaration-wrapper";

export function CookieDeclaration() {
  React.useEffect(() => {
    // Inject Cookiebot declaration
    if (isBrowser()) {
      const cookiebotCookieDeclaratioScript = document.createElement("script");
      cookiebotCookieDeclaratioScript.src =
        "https://consent.cookiebot.com/3b4d328a-786c-4982-9e00-255177cf8b73/cd.js";
      cookiebotCookieDeclaratioScript.type = "text/javascript";
      cookiebotCookieDeclaratioScript.async = true;

      const cookiebotWrapperEl = document.getElementById(
        COOKIEBOT_DECLARATION_WRAPPER_ID
      );
      if (cookiebotWrapperEl) {
        cookiebotWrapperEl.appendChild(cookiebotCookieDeclaratioScript);
      }
    }
  }, []);

  return <div id={COOKIEBOT_DECLARATION_WRAPPER_ID}></div>;
}
