import { Container } from '@mui/system';

export const HowItWorksVideos = ({ howItWorksTitle, howItWorksVideosD }) => {
  console.log('gg', howItWorksVideosD);
  return (
    <>
      <div id="hoe-werkt-het"></div>
      <section>
        <Container>
          <h2 style={{ textAlign: 'center' }}>{howItWorksTitle}</h2>
          <div className="video-grid-50">
            {howItWorksVideosD.map((item) => (
              <div key={item.strapi_id}>
                <video
                  src={item.video.url}
                  type="video/mp4"
                  controls
                  poster={item.placeholderImage.url}
                  style={{ maxWidth: '100%', borderRadius: '6px' }}
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            ))}
          </div>
        </Container>
      </section>
    </>
  );
};
