import { Container } from '@mui/system';

export const HighlightedProducts = ({ productPrice, productPricesTitle }) => {
  return (
    <div id="producten" style={{ margin: 0, padding: 0 }}>
      <section>
        <Container>
          <h2 style={{ textAlign: 'center' }}>{productPricesTitle}</h2>
          <div className="product-detail__grid" style={{ marginTop: '3rem' }}>
            <div>
              <img
                src={productPrice[0].image.url}
                alt={productPrice[0].image.alternativeText}
                style={{ maxWidth: '100%' }}
              ></img>
            </div>
            <div>
              {productPrice[0].priceTable.map((priceRule) => (
                <div
                  className="product-detail__price-grid"
                  key={priceRule.strapi_id}
                >
                  <h3>{priceRule.period}</h3>
                  <p>€ {priceRule.price}</p>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};
