exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-download-qr-scanned-tsx": () => import("./../../../src/pages/download/qr-scanned.tsx" /* webpackChunkName: "component---src-pages-download-qr-scanned-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-slider-tsx": () => import("./../../../src/pages/slider.tsx" /* webpackChunkName: "component---src-pages-slider-tsx" */),
  "component---src-templates-city-page-template-tsx": () => import("./../../../src/templates/CityPageTemplate.tsx" /* webpackChunkName: "component---src-templates-city-page-template-tsx" */),
  "component---src-templates-simple-page-template-tsx": () => import("./../../../src/templates/SimplePageTemplate.tsx" /* webpackChunkName: "component---src-templates-simple-page-template-tsx" */)
}

