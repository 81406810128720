import { Container } from '@mui/material';

export const HeroVideo = ({ video }) => {
  console.log(video);
  return (
    <section>
      <Container>
        {video.videoTitle && (
          <h2 style={{ textAlign: 'center' }}>{video.videoTitle}</h2>
        )}
        <video
          src={video.video.url}
          type="video/mp4"
          controls
          poster={video.placeholderImage.url}
          style={{ maxWidth: '100%', borderRadius: '6px' }}
        >
          Your browser does not support the video tag.
        </video>
      </Container>
    </section>
  );
};
