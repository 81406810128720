import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Container } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const Qrcode = () => {
  const [android, setAndroid] = useState(false);
  const [iphone, setIphone] = useState(false);

  useEffect(() => {
    if (/Android/i.test(navigator.userAgent)) {
      setAndroid(true);
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      setIphone(true);
    } else {
      navigate('/');
    }
  }, [android])

  useEffect(() => {
    setTimeout(() => {
      if (android) {
        window.location.href =
          'https://play.google.com/store/apps/details?id=nl.smartbak';
      } else if (iphone) {
        window.location.href =
          'https://apps.apple.com/nl/app/smartbak/id1628407941';
      } 
    }, 3000);
  }, [android, iphone]);

  return (
    <Container>
      <div
      style={{
        display: 'flex',
        flexDirection: "column",
        height: '100vh',
        padding: 24,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h1>Wij openen nu de {android && 'Play'} {iphone && 'App'} Store</h1>
      <CircularProgress />
    </div>
    </Container>
  );
};

export default Qrcode;
