import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Close from '../../images/icons/close-icon-dark.png';
import { Button, Container } from '@mui/material';

const drawerWidth = 300;
const Navbar = (props) => {
  const { window } = props;
  const scrolled = props.scrolled;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const data = useStaticQuery(graphql`
    query {
      strapiNavbar(locale: { eq: "nl-NL" }) {
        hamburger {
          alternativeText
          caption
          url
        }
        homeImage {
          alternativeText
          caption
          url
        }
        locale
        navbar {
          item
          link
          strapi_id
        }
      }
    }
  `);

  const { homeImage, hamburger, navbar } = data.strapiNavbar;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img src={Close} alt="close menu" width={24} style={{ padding: 24 }} />
      <Divider />
      <List>
        {navbar.map((item) => (
          <Link
            to={`/${item.link}`}
            key={item.strapi_id}
            activeClassName="active-nav"
          >
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.item} />
            </ListItemButton>
          </Link>
        ))}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" href="/download">
            Download de App
          </Button>
        </div>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className='navbar-wrapper'>
      <Box sx={{ display: 'flex' }}>
      <AppBar
        component="nav"
        sx={{ background: scrolled ? '#fff' : 'transparent' }}
        className={scrolled ? 'navbar-scrolled' : 'navbar-default'}
      >
        <div className='navbar-container'>
          <Toolbar>
            <Typography component="div" sx={{ padding: '10px 0' }}>
              <Link to="/">
                <img
                  src={`${homeImage.url}`}
                  alt={homeImage.alternativeText}
                  width={196}
                ></img>
              </Link>
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ ml: 'auto', display: { md: 'none' } }}
            >
              <img
                src={`${hamburger.url}`}
                alt={hamburger.alternativeText}
                className={scrolled ? 'burger-scrolled' : 'burger-default'}
              />
            </IconButton>
            <Box
              sx={{
                ml: 10,
                display: { xs: 'none', md: 'block' },
                alignItems: 'center',
              }}
            >
              {navbar.map((item) => (
                <Link
                  to={`/${item.link}`}
                  className={`button nav-button `}
                  key={item.strapi_id}
                  activeClassName="active-nav"
                >
                  {item.item}
                </Link>
              ))}
            </Box>
            <Box
              sx={{
                ml: 'auto',
                display: { xs: 'none', lg: 'block' },
                alignItems: 'center',
              }}
            >
              <Link
                to={`/download`}
                className={`button primary-button `}
                activeClassName="active-nav"
              >
                Download de app
              </Link>
            </Box>
          </Toolbar>
        </div>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
    </div>
  );
};

export default Navbar;
