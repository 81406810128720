import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ReactMarkdown from 'react-markdown';
import { pushDataLayerFaq } from '../datalayers/events';

const FaqsAccordion = ({ faq, clickedFaq, setClickedFaq, faqExpand }) => (
  <Accordion
    key={faq.faq.strapi_id}
    expanded={faq.faq.strapi_id === clickedFaq}
    onClick={() => {
      setClickedFaq(faq.faq.strapi_id);
      pushDataLayerFaq(faq.faq, clickedFaq);
    }}
  >
    <AccordionSummary
      expandIcon={
        <img src={`${faqExpand.url}`} alt={faqExpand.alternativeText}></img>
      }
      aria-controls="panel1a-content"
      id={`faqs-question-${faq.faq.question}`}
    >
      <div className="faq-question">{faq.faq.question}</div>
    </AccordionSummary>
    <AccordionDetails>
      <div className="faq-answer">
        <ReactMarkdown>{faq.faq.answer?.data?.answer}</ReactMarkdown>
      </div>
    </AccordionDetails>
  </Accordion>
);

export default FaqsAccordion;
