import { useStaticQuery, graphql } from 'gatsby';

export const AboutHome = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiHomepage(locale: { eq: "nl-NL" }) {
        aboutDescription {
          data {
            aboutDescription
          }
        }
        aboutImage {
          alternativeText
          caption
          url
        }
        aboutTitle
      }
    }
  `);

  const { aboutTitle, aboutDescription, aboutImage } = data.strapiHomepage;

  return (
    <>
      <div id="over-smartbak" style={{ marginBottom: '100px'}}></div>
      <section>
        <div
          style={{
            maxWidth: 920,
            padding: '0 12px',
            margin: '0 auto',
          }}
        >
          <div className="row-50">
            <div className="about-image">
              <img
                src={`${aboutImage.url}`}
                alt={aboutImage.alternativeText}
              ></img>
            </div>
            <div className="about-text">
              <h2>{aboutTitle}</h2>
              <p>{aboutDescription?.data?.aboutDescription}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
