import { graphql, useStaticQuery } from 'gatsby';
import { useState, useEffect } from 'react';

const DownloadAppButtons = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiHomepage(locale: { eq: "nl-NL" }) {
        appStore {
          image {
            alternativeText
            caption
            url
          }
          link
          strapi_id
        }
      }
    }
  `);

  const { appStore } = data.strapiHomepage;

  return (
    <div className="app-logos-hero">
      {appStore &&
        appStore.map((item: any) => (
          <div key={item.strapi_id}>
              <a href={item.link}>
                <img
                  src={`${item.image.url}`}
                  className="appstorelogo"
                  alt={item.image.alternativeText}
                ></img>
              </a>
          </div>
        ))}
    </div>
  );
};

export default DownloadAppButtons;
