import QRCode from '../../images/qr-code.png';

const QRButton = () => {
  return (
    <div style={{ flexDirection: 'column' }}>
      <p style={{ fontWeight: 600, fontSize: 12 }}>
        Scan de QR-code om te downloaden
      </p>
      <img
        src={QRCode}
        alt="qr-placeholder"
        style={{ border: '1px solid #ccc', width: '200px' }}
      />
            
    </div>
  );
};

export default QRButton;
